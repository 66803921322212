import React from "react";
import YouTubeVideoEmbed from "./videoEmbed";
import "../styles/videos.css";

function Videos() {
  return (
    <div className="videos-outermost">
      <div className="videos-container">
        <YouTubeVideoEmbed />
      </div>
    </div>
  );
}

export default Videos;
