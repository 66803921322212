import React from "react";
import HomeCard from "./homeCard";
import { useState } from "react";
import { poem } from "../constants/data";
import "../styles/home.css";
import Grid from "@mui/material/Grid";

function Home() {
  const [randomPoem] = useState(poem[Math.floor(Math.random() * poem.length)]);
  const [name, setName] = useState("अलका देशपांडे");
  // const [intro, setIntro] = useState(
  //   "Discover the enchanting realm of words where emotions flow freely and stories come to life. I am Alka, a passionate writer and poet, weaving tales and verses that capture the essence of life's journey. Step into my world, where imagination knows no bounds, and emotions are painted in vibrant hues of prose and poetry.Whether you seek to get lost in the realms of fiction or find solace in the simplicity of verses, you'll find a treasure trove of words waiting to stir your soul."
  // );

  return (
    <div className="outermost">
      <div className="container">
        <div className="first-content">
          <div className="profile-photo">
            <img src="profilePhoto.jpg" alt="" width="200" />
          </div>
          <div className="introduction">
            <p>
              <h2
                className="name"
                onMouseLeave={() => setName("अलका देशपांडे")}
                onMouseEnter={() => setName("Alka Deshpande")}
              >
                {name}
              </h2>
              <div
              // onMouseLeave={() =>
              //   setIntro(
              //     "Discover the enchanting realm of words where emotions flow freely and stories come to life. I am Alka, a passionate writer and poet, weaving tales and verses that capture the essence of life's journey. Step into my world, where imagination knows no bounds, and emotions are painted in vibrant hues of prose and poetry.Whether you seek to get lost in the realms of fiction or find solace in the simplicity of verses, you'll find a treasure trove of words waiting to stir your soul."
              //   )
              // }
              // onMouseEnter={() =>
              //   setIntro(
              //     "शब्दांच्या आकर्षक जगाची आवड घेऊन जा, तेथे भावना स्वतंत्रपणे प्रवाहित होतात आणि कथा आजरामर आवश्यके जीवनाच्या सारख्या प्रकरणांमध्ये आलेल्या आहे. माझं नाव अल्का आहे, एक उत्सुक लेखिका आणि कवि, जीवनाच्या आत्मा घेणार्या कथा आणि चांगल्या कविता लिहित आहे. माझ्या जगात्या चरणांच्या मोडीकडून चालणारा आहे, आणि भावना प्रोझ आणि कवितेच्या चमकदार रंगांमध्ये विचित्रित केलेल्या आहे.किंवा कवितेच्या साधीतील सुकून मिळवून त्यातली दुकान आहे, आपली आत्मा उत्तेजित करणार्या शब्दांची खझाण मिळेल."
              //   )
              // }
              >
                {/* {intro} */}
                Discover the enchanting realm of words where emotions flow
                freely and stories come to life. I am Alka, a passionate writer
                and poet, weaving tales and verses that capture the essence of
                life's journey. Step into my world, where imagination knows no
                bounds, and emotions are painted in vibrant hues of prose and
                poetry.Whether you seek to get lost in the realms of fiction or
                find solace in the simplicity of verses, you'll find a treasure
                trove of words waiting to stir your soul.
              </div>
            </p>
          </div>
        </div>
        <div className="cards">
          <Grid container spacing={2}>
            <HomeCard endpoint="poems" title="Poems" image="thumbnail_3.jpg" />
            <HomeCard
              endpoint="articles"
              title="Articles"
              image="thumbnail_1.jpg"
            />
            <HomeCard endpoint="gita" title="Gita" image="thumbnail_4.jpg" />
          </Grid>
        </div>
        <div className="random-poem">
          <h2>Author's Pick</h2>
          {randomPoem ? (
            <div>
              <h3>{randomPoem.title}</h3>
              <div className="poem-text">
                {randomPoem.content.map((str, index) => (
                  <div key={index}>{str ? str : <br />}</div>
                ))}
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
