import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./App.css";
import Home from "./components/home";
import Poems from "./components/poems";
import Articles from "./components/articles";
import Gita from "./components/gita";
import Pictures from "./components/pictures";
import Contact from "./components/contact";
import Videos from "./components/videos";
import Layout from "./components/layout";

import { RecoilRoot } from "recoil";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/poems",
        element: <Poems />,
      },

      {
        path: "/articles",
        element: <Articles />,
      },
      {
        path: "/gita",
        element: <Gita />,
      },
      {
        path: "/pictures",
        element: <Pictures />,
      },
      {
        path: "/contact",
        element: <Contact />,
      },
      {
        path: "/videos",
        element: <Videos />,
      },
    ],
  },
]);

function App() {
  return (
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  );
}
export default App;
