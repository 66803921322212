import React from "react";
import "../styles/contact.css";

function Contact() {
  return (
    <div className="contact-outermost">
      <div className="contact-inner">
        <div className="contact-container">
          <div className="contact-details">
            <img
              className="profile-photo"
              src="profilePhoto.jpg"
              alt=""
              width="200"
            />

            <div className="contact-text">
              <h2>Get in touch!</h2>
              <p>
                I've had the joy of weaving stories since my earliest memories,
                and with each passing year, my love for the written word has
                only grown. Crafting tales and sharing them has become not just
                a hobby but a cherished part of my life.
              </p>
              <p>
                As I now share this with a larger reader group, I would be
                absolutely delighted to hear from you. I would love to hear your
                thoughts, questions, or comments. Let's connect, and share our
                love for the written word!
              </p>
              <div className="contact-info">
                <div id="main-email">Email: alkad@rediffmail.com </div>
                <div id="main-phone">Phone no: 8197206346</div>
                <a
                  className="email-link"
                  href="mailto:alkad@rediffmail.com?subject=Hello%20There&body=Hi%2C%0A%0AI%20hope%20you%20are%20doing%20well.%20"
                >
                  Send Email
                </a>
              </div>
            </div>
          </div>

          <div className="our-info">
            {/* <div className="our-info-1">
              <p>Website Powered by:</p>
              <h3 className="company-name">Avyaya Technologies</h3>
            </div> */}
            <div className="our-info-2">
              <p>Enquiries: </p>
              <div id="email-1">manjiripathak01@gmail.com</div>
              <div id="email-2">sidthokal@gmail.com</div>
              <a
                className="email-link"
                href="mailto:manjiripathak01?subject=Hello%20There&body=Hi%2C%0A%0AI%20hope%20you%20are%20doing%20well.%20"
              >
                Send Email
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
