import React from "react";
import { useState, useEffect } from "react";
import "../styles/articles.css";
import { articles } from "../constants/data";

import CommentsSection from "./CommentsSection";

function IndexSection({ data, onItemClick }) {
  return (
    <div className="article-index">
      <h2>INDEX</h2>
      <ul className="index-list">
        {data.map((item) => (
          <div className="list-item-div">
            <li key={item.id} onClick={() => onItemClick(item.id)}>
              {item.title}
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
}

function ContentDisplay({ selectedItem }) {
  return (
    <div className="article-content">
      {selectedItem && (
        <div>
          <h2>{selectedItem.title}</h2>
          <div>
            {selectedItem.content.map((str, index) => (
              <div key={index}>{str ? str : <br />}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function Articles() {
  const data = articles;

  const [selectedItemId, setSelectedItemId] = useState(1);
  const [showMenu, setShowMenu] = useState("true");
  const [isMobile, setIsMobile] = useState("false");
  const [windowWidth, setWindowWidth] = useState("1024");

  const handleResize = () => {
    //setWindowWidth(window.innerWidth);
    console.log(window.innerWidth);
    if (window.innerWidth <= 960) {
      setIsMobile("true");
    } else setIsMobile("false");
  };

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile("true");
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onItemClick = (id) => {
    setSelectedItemId(id);
    setShowMenu("true");
    scrollToTop();
  };

  const onItemClickMobile = (id) => {
    console.log("YES MOBILE");
    setSelectedItemId(id);
    setShowMenu();
    scrollToTop();
  };

  const renderData = () => {
    return (
      <div className="articles-outermost">
        <div className="articles-container">
          <div className="buttons-index">
            <div className="index-button">
              <button
                className={
                  showMenu ? "index-button-invisible" : "index-button-visible"
                }
                onClick={() => setShowMenu(!showMenu)}
              >
                INDEX
              </button>
              {showMenu ? (
                <div className="menu">
                  <IndexSection
                    data={data}
                    onItemClick={
                      isMobile == "true" ? onItemClickMobile : onItemClick
                    }
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>

          <ContentDisplay
            selectedItem={data.find((item) => item.id === selectedItemId)}
          />
          <CommentsSection data={data} />
        </div>
      </div>
    );
  };

  return <div>{data && renderData()}</div>;
}

export default Articles;
