import React, { useState, useEffect } from "react";
import "../styles/pictures.css"; // Import the CSS file

const images = [
  "IMG_1.jpg",
  "IMG_2.jpg",
  // "IMG_3.jpg",
  // "IMG_3.jpg",
  // "IMG_3.jpg",
  // "IMG_3.jpg",
  // "IMG_3.jpg",
  // Add more image URLs as needed
];

function Pictures() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    let intervalId;

    if (!isPaused) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000); // Change slide every 3 seconds (3000 milliseconds)
    }

    return () => clearInterval(intervalId);
  });

  const togglePause = () => {
    setIsPaused((prevState) => !prevState);
  };

  return (
    <div className="carousel-outermost">
      <div className="carousel-container">
        <div className="carousel-controls">
          <button onClick={togglePause}>{isPaused ? "Resume" : "Pause"}</button>
        </div>
        <div className="carousel-image">
          <img src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
        </div>
      </div>
    </div>
  );
}

export default Pictures;
