import React from "react";
import { useState, useEffect } from "react";
import { poem } from "../constants/data";
import "../styles/poems.css";

import CommentsSection from "./CommentsSection";

function IndexSection({ data, onItemClick }) {
  return (
    <div className="poem-index">
      <h2>INDEX</h2>
      <ul className="poem-index-list">
        {data.map((item) => (
          <div className="list-item-div">
            <li
              key={item.id}
              onClick={() => {
                onItemClick(item.id);
              }}
            >
              {item.title}
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
}

function ContentDisplay({ selectedItem }) {
  return (
    <div className="poem-content">
      {selectedItem && (
        <div>
          <h2>{selectedItem.title}</h2>
          <div>
            {selectedItem.content.map((str, index) => (
              <div key={index}>{str ? str : <br />}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function Poems() {
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [showMenu, setShowMenu] = useState("true");
  const [isMobile, setIsMobile] = useState("false");
  const [windowWidth, setWindowWidth] = useState("");

  const handleResize = () => {
    //setWindowWidth(window.innerWidth);
    console.log(window.innerWidth);
    if (window.innerWidth <= 960) {
      setIsMobile("true");
    } else setIsMobile("false");
  };

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile("true");
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(window.innerWidth);
  console.log(isMobile);

  const data = poem;

  const onItemClick = (id) => {
    console.log("NOT MOBILE");
    setSelectedItemId(id);
    setShowMenu("true");
    scrollToTop();
  };

  const onItemClickMobile = (id) => {
    console.log("YES MOBILE");
    setSelectedItemId(id);
    setShowMenu();
    scrollToTop();
  };

  const [selectedLanguage, setSelectedLanguage] = useState("All");

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const filteredData =
    selectedLanguage === "All"
      ? data
      : data.filter((item) => item.language === selectedLanguage);

  const renderData = () => {
    return (
      <div className="poems-outermost">
        <div className="poems-container">
          <div className="buttons-index">
            <div className="index-button">
              <button
                className={
                  showMenu ? "index-button-invisible" : "index-button-visible"
                }
                onClick={() => setShowMenu(!showMenu)}
              >
                INDEX
              </button>
              {showMenu ? (
                <div className="menu">
                  <div className="buttons">
                    <button
                      className="language-button"
                      id="marathi-button"
                      onClick={() => handleLanguageChange("Marathi")}
                    >
                      Marathi
                    </button>
                    <button
                      className="language-button"
                      id="hindi-button"
                      onClick={() => handleLanguageChange("Hindi")}
                    >
                      Hindi
                    </button>
                    <button
                      className="language-button"
                      id="all-button"
                      onClick={() => handleLanguageChange("All")}
                    >
                      All
                    </button>
                  </div>
                  <IndexSection
                    data={filteredData}
                    onItemClick={
                      isMobile == "true" ? onItemClickMobile : onItemClick
                    }
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <ContentDisplay
            selectedItem={data.find((item) => item.id === selectedItemId)}
          />
          <CommentsSection data={data} />
        </div>
      </div>
    );
  };

  return <div>{data && renderData()}</div>;
}

export default Poems;
