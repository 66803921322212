import React from "react";
import "../styles/footer.css";
import { NavLink } from "react-router-dom";

const yearNow = new Date().getFullYear();
function Footer() {
  return (
    <div className="footer">
      Copyright © {yearNow}
      <NavLink className="footer-link" to="/contact">
        Contact Me
      </NavLink>
    </div>
  );
}

export default Footer;
