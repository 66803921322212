import React, { useState } from "react";

import { reviews } from "../constants/data";

function ContentDisplay({ selectedItem }) {
  return (
    <div className="comment-content">
      {selectedItem && (
        <div>
          <h2>{selectedItem.title}</h2>
          <div>
            {selectedItem.content.map((str, index) => (
              <div key={index}>{str ? str : <br />}</div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

const CommentsSection = () => {
  const [random_index] = useState(Math.floor(Math.random() * reviews.length));

  return (
    <div>
      <div className="commentsOuterContainer">
        <h2>COMMENTS</h2>
        <div className="commentsInnerContainer">
          <ContentDisplay selectedItem={reviews[random_index]} />
        </div>
      </div>
    </div>
  );
};

export default CommentsSection;
