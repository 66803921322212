import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

export default function HomeCard(props) {
  return (
    <Grid item xs={12} sm={6} md={4} lg={4}>
      <Card className="card-component">
        <Link to={`/${props.endpoint}`} className="card-link">
          <CardActionArea>
            <CardMedia
              component="img"
              height="140"
              image={props.image}
              alt="green iguana"
            />
            <CardContent className="card-content">
              <Typography
                // gutterBottom
                variant="h5"
                component="div"
                className="card-title"
              >
                {props.title}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    </Grid>
  );
}
