import React from "react";
import { useState, useEffect } from "react";
import { gita } from "../constants/data";
import "../styles/gita.css";

import CommentsSection from "./CommentsSection";

function IndexSection({ data, onItemClick }) {
  return (
    <div className="gita-index">
      <h2>INDEX</h2>
      <ul className="gita-index-list">
        {data.map((item) => (
          <div className="list-item-div">
            <li
              key={item.id}
              onClick={() => {
                onItemClick(item.id);
              }}
            >
              {item.title}
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
}

// function getStatus(status) {
//   switch (status) {
//     case "active":
//       return <span style={{ color: "green" }}>Active</span>;
//     case "inactive":
//       return <span style={{ color: "red" }}>Inactive</span>;
//     case "pending":
//       return <span style={{ color: "blue" }}>Pending</span>;
//     default:
//       return <span>Unknown</span>;
//   }
// }

function ContentDisplay({ selectedItem }) {
  return (
    <div className="gita-content">
      {selectedItem && (
        <div>
          <h2>{selectedItem.title}</h2>
          <div>
            {selectedItem.content.map((str, index) => (
              <div
                key={index}
                className={
                  str[0] == "B"
                    ? "bold-text normal-text"
                    : str[0] == "$"
                    ? "center-text normal-text"
                    : str[0] == "&"
                    ? "center-text bold-text normal-text"
                    : "normal-text"
                }
              >
                {str ? (
                  (str =
                    str[0] === "B"
                      ? str.slice(5)
                      : str[0] === "$"
                      ? str.slice(5)
                      : str[0] === "&"
                      ? str.slice(5)
                      : str)
                ) : (
                  <br />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function Gita() {
  const [selectedItemId, setSelectedItemId] = useState(1);
  const [showMenu, setShowMenu] = useState("true");
  const [isMobile, setIsMobile] = useState("false");

  const handleResize = () => {
    //setWindowWidth(window.innerWidth);
    console.log(window.innerWidth);
    if (window.innerWidth <= 768) {
      setIsMobile("true");
    } else setIsMobile("false");
  };

  useEffect(() => {
    if (window.innerWidth <= 960) {
      setIsMobile("true");
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(window.innerWidth);
  console.log(isMobile);

  const data = gita;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const onItemClick = (id) => {
    setSelectedItemId(id);
    setShowMenu("true");
    scrollToTop();
  };

  const onItemClickMobile = (id) => {
    console.log("YES MOBILE");
    setSelectedItemId(id);
    setShowMenu("");
    scrollToTop();
  };

  const renderData = () => {
    return (
      <div className="gita-outermost">
        <div className="gita-container">
          <div className="index-button">
            <button
              className={
                showMenu ? "index-button-invisible" : "index-button-visible"
              }
              onClick={() => setShowMenu(!showMenu)}
            >
              INDEX
            </button>
            {showMenu ? (
              <div className="menu">
                <IndexSection
                  data={data}
                  onItemClick={
                    isMobile == "true" ? onItemClickMobile : onItemClick
                  }
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>

          <ContentDisplay
            selectedItem={data.find((item) => item.id === selectedItemId)}
          />
          <CommentsSection />
        </div>
        {/* <YouTubeVideoEmbed /> */}
      </div>
    );
  };

  return <div>{data && renderData()}</div>;
}

export default Gita;
